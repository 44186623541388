import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VariantName } from 'app/model/entities/annotatedVariant';

@Component({
  selector: 'app-variant-title',
  templateUrl: './variant-title.component.html',
  styles: [
    `
      .expand-bio {
        font-weight: bold;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantTitleComponent implements OnInit {
  @Input() name: VariantName[];
  @Input() multipleLines = false;
  @Input() showAlias = true;
  // expandBiomarker - Due to requirement in IST-732, IST-589
  // We want to show an expanded name in variant cards
  @Input() expandBiomarker = false;
  @Input() boldFirstName = true;
  @Input() truncateLength = 41;

  expandedBiomarkerMap = new Map<string, boolean>();

  ngOnInit(): void {
    this.name?.forEach((name: VariantName) => {
      this.expandedBiomarkerMap.set(
        name.first,
        this.expandBiomarker && ['MSS', 'MSI', 'TMB', 'HRDsig', 'Genomic LOH'].includes(name.first)
      );
    });
  }
}
